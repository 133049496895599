@font-face {
  font-family: 'FuturaPT-Bold'; /*a name to be used later*/
  src: url('./assets/fonts/futura-pt-bold.ttf'); /*URL to font*/
}
@font-face {
  font-family: 'FuturaPT-Book'; /*a name to be used later*/
  src: url('./assets/fonts/futura-pt-book-oblique.ttf'); /*URL to font*/
}
@import url('https://fonts.googleapis.com/css2?family=Lalezar&family=Oswald:wght@700&display=swap');
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #121517;
  font-family: 'FuturaPT-Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.active {
  text-decoration: none !important;
  margin-bottom: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
